<template>
    <div class="visualizer">
        <div :class="this.showcontrols
            ? 'visualizer__controls show'
            : 'visualizer__controls'
            ">
            <input type="file" accepts="audio/*" class="fileupload" />
            <label for="blur-range">Blur Range</label>
            <input type="range" name="blur-range" value="10" max="75" class="visualizer__blur-range" />
            <label for="contrast-range">Contrast Range</label>
            <input type="range" name="contrast-range" value="10" max="10" step="0.1" class="visualizer__contrast-range" />
        </div>
        <div class="visualizer__container">
            <canvas class="visualizer__canvas"></canvas>
            <audio src="" id="audio1" crossorigin></audio>
        </div>
    </div>
</template>

<script>
export default {
    name: "Visualizer",
    props: {
        audiosrc: HTMLAudioElement,
        playviz: Boolean,
        showcontrols: Boolean,
    },
    data() {
        return {
            canvas: HTMLCanvasElement,
            ctx: "",
            audio1: "",
            audioSource: "",
            analyser: "",
            bufferLength: "",
            dataArray: Array,
            barWidth: Number,
            audioCtx: "",
            files: "",
            runit: false,
            visualizer: HTMLElement,
        };
    },
    mounted() {
        this.canvas = document.querySelector(".visualizer__canvas");
        this.ctx = this.canvas.getContext("2d");
        this.files = document.querySelector(".fileupload");
        this.barWidth = 10;
        this.barHeight;

        //manual file upload
        this.files.addEventListener("change", (event) => {
            this.audio1 = document.querySelector("#audio1");
            this.audio1.src = URL.createObjectURL(event.target.files[0]);
            this.audio1.load();
            this.audio1.play();
            this.audioCtx = new AudioContext();
            if (!this.audioSource) {
                this.audioSource = this.audioCtx.createMediaElementSource(
                    this.audio1
                );
                this.analyser = this.audioCtx.createAnalyser();
                this.audioSource.connect(this.analyser);
                this.analyser.fftSize = 256;
                // this.analyser.connect(this.audioCtx.destination);
                this.bufferLength = this.analyser.frequencyBinCount;
                this.dataArray = new Uint8Array(this.bufferLength);
            }
            this.runVisualizer();
        });

        this.visualizer = document.querySelector(".visualizer");
        const blurRange = this.visualizer.querySelector(
            ".visualizer__blur-range"
        );
        const contrastRange = this.visualizer.querySelector(
            ".visualizer__contrast-range"
        );
        this.setCssVar("--blur-range", `${blurRange.value}px`);
        this.setCssVar("--contrast-range", contrastRange.value);
        blurRange.addEventListener("change", (event) => {
            this.setCssVar("--blur-range", `${event.target.value}px`);
        });
        contrastRange.addEventListener("change", (event) => {
            this.setCssVar("--contrast-range", event.target.value);
        });
    },
    methods: {
        activateMic() {
            console.log("activating mic");

            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    const audioCtx = new AudioContext();
                    this.analyser = audioCtx.createAnalyser();
                    this.analyser.fftSize = 256;
                    this.bufferLength = this.analyser.frequencyBinCount;
                    this.dataArray = new Uint8Array(this.bufferLength);
                    const streamSrc = audioCtx.createMediaStreamSource(stream);
                    streamSrc.connect(this.analyser);
                    this.runVisualizer();
                });
        },
        setCssVar(property, value) {
            this.visualizer.style.setProperty(`${property}`, `${value}`);
        },
        animate() {
            let x = 0;
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.analyser.getByteFrequencyData(this.dataArray);
            this.drawVisualiser(
                this.bufferLength,
                x,
                this.barWidth,
                this.barHeight,
                this.dataArray
            );
            requestAnimationFrame(this.animate);
        },
        drawVisualiser(bufferLength, x, barWidth, barHeight, dataArray) {
            for (let index = 0; index < bufferLength; index++) {
                barHeight = dataArray[index] * 1;
                this.ctx.save();
                this.ctx.translate(
                    this.canvas.width / 2,
                    this.canvas.height / 2
                );
                this.ctx.rotate((index * (Math.PI * 20)) / bufferLength);
                const hue = index * 1;
                this.ctx.fillStyle = `hsl(${hue}, 100%, ${barHeight / 3}%)`;
                this.ctx.fillRect(0, 0, barWidth / 2, barHeight / 2);
                x += barWidth / 2;
                this.ctx.restore();
            }
        },
        runVisualizer() {
            this.animate(this.analyser);
        },
    },
    watch: {
        playviz() {
            if (this.playviz) {
                setTimeout(() => {
                    this.audioCtx = new AudioContext();
                    this.analyser = this.audioCtx.createAnalyser();
                    this.analyser.fftSize = 256;
                    this.bufferLength = this.analyser.frequencyBinCount;
                    this.dataArray = new Uint8Array(this.bufferLength);
                    let mediaStream;
                    if (!this.currentAudio.captureStream) {
                        mediaStream = this.currentAudio.mozCaptureStream();
                    } else {
                        mediaStream = this.currentAudio.captureStream();
                    }
                    const streamSrc =
                        this.audioCtx.createMediaStreamSource(mediaStream);
                    streamSrc.connect(this.analyser);
                    this.analyser.connect(this.audioCtx.destination);
                    this.runVisualizer();
                }, 1000);
            }
        },
    },
    computed: {
        currentAudio() {
            return this.audiosrc;
        },
    },
};
</script>

<style lang="scss">
.visualizer {
    display: grid;
    width: 100%;
    height: 85%;
    z-index: 999;
    position: relative;
    max-height: 100vh;
    aspect-ratio: 1;

    &__container {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        margin: auto;
    }

    &__controls {
        z-index: 999;
        place-self: center flex-end;
        display: grid;
        gap: 10px;
        opacity: 0;
        pointer-events: none;

        &.show {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__canvas {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        margin: auto;
        filter: blur(var(--blur-range)) contrast(var(--contrast-range));
    }

    .fileupload {
        color: white;
        height: 20px;
        width: max-content;
        z-index: 999;
        place-self: flex-start center;
    }
}

.visualizer__button {
    margin: auto;
}

.visualizer__button:hover {
    cursor: pointer;
}
</style>