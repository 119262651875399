<template>
  <div class="video-player">
    <h2 class="video-player__title">{{ title }}</h2>
    <div class="video-player__content">
      <Video v-for="(video, index) in filteredVideoFiles" :key="index" :source="video.source_url"
        :title="video.title.rendered"></Video>
    </div>
  </div>
</template>

<script>
import Video from "./Video";
export default {
  name: "VideoPlayer",
  components: { Video },
  props: {
    media: Array,
    title: String,
  },
  data: function () {
    return {};
  },
  mounted() { },
  methods: {},
  computed: {
    filteredVideoFiles() {
      const videoFiles = this.media.filter(
        (file) => file["mime_type"] == "video/mp4"
      );
      return videoFiles.filter((file) => file.categories[0] === 3);
    },
  },
};
</script>

<style lang="scss">
@use "../scss/buttons";
@use "../scss/variables";
@use "../scss/breakpoints";
@use "../scss/core-mixins";

.video-player {
  grid-area: main;
  display: grid;
  padding: 20px;
  gap: 10px;
  align-items: flex-start;
  z-index: 99;
  grid-template:
    "title" min-content
    "controls"
    "content" / 1fr;

  @include core-mixins.device(breakpoints.$tabPortrait) {
    padding: 20px;
    grid-template:
      "title" min-content
      "controls"
      "content" / 1fr;
  }

  &__title {
    text-shadow: 2px 2px red;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__controls {
    grid-area: controls;
  }

  &__content {
    grid-area: content;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    gap: 15px;
    align-items: flex-start;
  }
}
</style>