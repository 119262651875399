<template>
    <div class="audio-player">
        <h2 class="audio-player__title">{{ title }}</h2>
        <div class="audio-player__controls">
            <button aria-label="Skip backwards" @click="skipBackwards" class="
                    audio-player__skip
                    audio-player__controls__button
                    audio-player__skip--backwards
                ">
                -15s
            </button>
            <button aria-label="Skip forwards" @click="skipForwards" class="
                    audio-player__skip
                    audio-player__controls__button
                    audio-player__skip--forwards
                ">
                +15s
            </button>
            <button aria-label="Play or stop all audio" v-on="mediaPlaying
                ? { click: stopAudio }
                : { click: playAllPlaylist }
                " :class="{
        'audio-player__stop-button': mediaPlaying,
        'play-btn': !mediaPlaying,
        'audio-player__controls__button': true,
    }"></button>
            <button aria-label="Skip backwards" @click="playPrev" class="
                    audio-player__skip
                    audio-player__controls__button
                    audio-player__skip--backwards
                ">
                Prev
            </button>
            <button aria-label="Skip forwards" @click="playNext" class="
                    audio-player__skip
                    audio-player__controls__button
                    audio-player__skip--forwards
                ">
                Next
            </button>
        </div>
        <div class="audio-player__playlist audio-player__content">
            <div class="
                    audio-player__playlist__item
                    media-container__content__item
                " v-for="(source, index) in filteredAudioFiles" :key="index" :id="source.id" draggable="true"
                @dragstart="startDrag($event, item)">
                <p :data-trackname="source.title.rendered" :data-trackid="source.id"
                    class="audio-player__playlist__item__title" v-html="source.title.rendered" @click="playAudio"></p>
                <button aria-label="play audio" :data-trackname="source.title.rendered" :data-trackid="source.id"
                    @click="playIndividualTrack" class="play-button"></button>
                <button aria-label="Add to playlist" @click="addToPlaylist($event)" :data-trackname="source.title.rendered"
                    :data-trackid="source.id" class="audio-player__playlist__add-button add-button"></button>
            </div>
        </div>
        <button aria-label="show hide playlist" @click="showHide" :class="itemsInPlaylist && !playlistInView
            ? 'show audio-player__playlist__button--show-hide'
            : 'audio-player__playlist__button--show-hide'
            ">
            {{ !playlistInView ? " Show playlist" : "Hide playlist" }}
        </button>

        <div :class="{ show: mediaPlaying, 'audio-player__message-box': true }">
            {{ currentMessage }}
        </div>
        <div :class="{
            show: mediaPlaying || loading,
            'audio-player__now-playing': true,
        }">
            <div :class="{
                loading: loading,
                'lds-ellipsis audio-player__spinner': true,
            }">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h4 class="audio-player__now-playing__track-title" v-html="currentTrackTitle"></h4>
            <div class="audio-player__now-playing__track-time">
                <span>{{ currentTrackTime }}</span><span> / </span><span>{{ updateTimeRemaining }}</span>
            </div>
        </div>
        <div :class="{
            hidden: !playlistInView,
            'audio-player__playlist-container': true,
        }">
            <div class="audio-player__playlist__button-container">
                <h2 class="audio-player__playlist-container__title">
                    Playlist
                </h2>
                <button class="audio-player__playlist__close" @click="showHide">
                    X
                </button>
                <button aria-label="clear playlist" @click="clearPlaylist" class="
                        audio-player__playlist__button
                        audio-player__playlist__button--clear
                    ">
                    Clear Playlist
                </button>
                <button aria-label="play all playlist" @click="playAllPlaylist($event)"
                    class="audio-player__playlist__play">
                    Play All
                </button>
            </div>
            <ul :class="{
                empty: this.playlist.length === 0,
                'audio-player__playlist-container__list': true,
            }" @drop="onDrop($event, 1)" @dragenter.prevent="" @dragover.prevent="">
                <li v-for="(item, index) in playlist" :key="item.id" :data-trackid="item.id" :data-index="index"
                    draggable="true" class="audio-player__playlist-container__list-item">
                    <span class="audio-player__playlist-container__number">{{ index + 1 }}.
                    </span>
                    {{ item.title.rendered }}
                    <span @click="removeItem($event)" class="audio-player__playlist-container__remove">(remove)</span>
                    <span @click="shiftPlaylistPosition($event, 'down')" class="
                            audio-player__playlist-container__shift
                            audio-player__playlist-container__shift--down
                        "></span>
                    <span @click="shiftPlaylistPosition($event, 'up')" class="
                            audio-player__playlist-container__shift
                            audio-player__playlist-container__shift--up
                        "></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "AudioPlayer",
    props: {
        media: Array,
        title: String,
    },
    data() {
        return {
            playlistItems: "",
            playlist: [],
            playlistInView: false,
            playlistPosition: 0,
            usingPlaylist: false,
            currentTrackTime: "",
            currentTimeRemaining: "",
            currentTrackTitle: "",
            audioPlayer: HTMLAudioElement,
            stopButton: "",
            spinner: "",
            body: "",
            mediaPlaying: false,
            loading: false,
            currentMessage: "",
            messageBox: "",
            timeInterval: "",
            supportedAudioFormats: ["flac", "mp3", "wav"],
        };
    },
    mounted() {
        this.body = document.querySelector("body");
        this.stopButton = document.querySelector(".audio-player__stop-button");
        this.spinner = document.querySelector(".audio-player__spinner");
        this.messageBox = document.querySelector(".audio-player__message-box");
        this.audioPlayer = new Audio();
        this.audioPlayer.setAttribute("crossorigin", "anonymous");
        this.audioPlayer.preload = "metadata";
        this.audioPlayer.onloadstart = () => {
            this.loading = true;
        };
        this.audioPlayer.onloadeddata = () => {
            this.loading = false;
        };
        this.audioPlayer.addEventListener("playing", () => {
            console.log("playing");
            this.mediaPlaying = true;
            this.currentTrackTime = this.convertSecondsToTime(
                this.audioPlayer.duration
            );
            this.startStopTimer();
        });
        this.audioPlayer.addEventListener("ended", () => {
            console.log("ended");
            this.mediaPlaying = false;
            if (this.playlistPosition >= this.playlist.length - 1) {
                this.stopAudio();
            } else {
                this.playNext();
            }
            this.startStopTimer();
        });
        this.audioPlayer.addEventListener("pause", () => {
            console.log("pausing");
            this.mediaPlaying = false;
            this.startStopTimer();
        });
    },
    methods: {
        showHide() {
            this.playlistInView = !this.playlistInView;
        },
        showMessage() {
            this.messageBox.animate(
                [
                    { opacity: 0 },
                    { opacity: 1, offset: 0.2 },
                    { opacity: 1, offset: 0.8 },
                    { opacity: 0 },
                ],
                { duration: 1500 }
            );
        },
        enableCountdown(enable) {
            if (enable !== false) {
                this.currentTimeRemaining = "";
                this.timeInterval = setInterval(() => {
                    if (this.calculateTimeRemaining() > 0) {
                        this.currentTimeRemaining =
                            this.calculateTimeRemaining();
                    }
                }, 1000);
            } else {
                clearInterval(this.timeInterval);
            }
        },
        calculateTimeRemaining() {
            let time = this.audioPlayer.duration - this.audioPlayer.currentTime;
            return time;
        },
        convertSecondsToTime(seconds) {
            let current = Math.floor(seconds);
            let minutes = Math.floor(current / 60);
            let secondsRemaining = current - minutes * 60;
            if (secondsRemaining < 10) {
                secondsRemaining = `0${secondsRemaining}`;
            }
            return `${minutes}.${secondsRemaining}`;
        },
        playIndividualTrack(event) {
            this.stopAudio();
            document
                .querySelectorAll(".audio-player__playlist__item")
                .forEach((item) => item.classList.remove("playing"));
            requestAnimationFrame(() => {
                this.clearPlaylist();
                this.addToPlaylist(event);
                this.playAllPlaylist();
                event.target.parentElement.classList.add("playing");
            }, 1);
        },
        playAudio(trackID) {
            if (this.mediaPlaying) {
                console.log("stopping audio");
                this.stopAudio();
            }
            const file = this.filteredAudioFiles.find(
                (file) => file.id == trackID
            );
            this.audioPlayer.src = file.source_url;
            this.currentTrackTitle = file.title.rendered;
            this.audioPlayer.play();
            this.body.classList.add("media-playing");
        },
        skipForwards() {
            this.audioPlayer.currentTime = Math.round(
                this.audioPlayer.currentTime + 15
            );
            this.audioPlayer.play();
        },
        skipBackwards() {
            this.audioPlayer.currentTime = this.audioPlayer.currentTime - 15;
            this.audioPlayer.play();
        },
        addToPlaylist(event) {
            this.currentMessage = "Track added to playlist";
            this.showMessage();
            const trackId = event.target.dataset.trackid;
            const track = this.filteredAudioFiles.filter(
                (item) => item.id === Number(trackId)
            );
            this.playlist.push(...track);
        },
        clearPlaylist() {
            this.playlist = [];
            this.playlistPosition = 0;
        },
        playPrev() {
            this.playlistPosition--;
            if (
                this.playlistPosition < this.playlist.length &&
                this.playlistPosition > -1
            ) {
                this.playAllPlaylist(this.playlistPosition);
            } else {
                this.playAllPlaylist(this.playlistPosition++);
            }
        },
        playNext() {
            ++this.playlistPosition;
            if (this.playlistPosition < this.playlist.length) {
                this.playAllPlaylist(this.playlistPosition);
            } else {
                this.playAllPlaylist(this.playlistPosition--);
            }
        },
        addAllToPlaylist() {
            this.playlist.push(...this.filteredAudioFiles);
        },
        playAllPlaylist(playlistPosition = 0) {
            if (typeof playlistPosition !== "number") {
                playlistPosition = 0;
            }
            this.usingPlaylist = true;
            if (this.playlist.length === 0) {
                this.addAllToPlaylist();
                this.currentMessage = "Playing all tracks";
                this.showMessage();
                const id = this.playlist[playlistPosition].id;
                this.playAudio(id);
                return;
            }
            if (this.playlistPosition >= this.playlist.length - 1) {
                this.playlistPosition = this.playlist.length - 1;
            }
            const id = this.playlist[playlistPosition].id;
            this.playAudio(id);
        },
        shiftPlaylistPosition(event, direction) {
            const currentId = Number(
                event.target.parentElement.dataset.trackid
            );
            const currentTrack = this.media.filter(
                (file) => file.id === currentId
            )[0];
            let currentPosition = 0;
            this.playlist.forEach((item, index) => {
                if (item.id === currentId) {
                    currentPosition = index;
                }
            });

            const positionMod =
                direction === "down"
                    ? currentPosition + 1
                    : currentPosition - 1;
            if (positionMod < 0 || positionMod >= this.playlist.length) {
                return;
            }
            const newList = this.playlist.filter(
                (track, index) => index !== currentPosition
            );
            newList.splice(positionMod, 0, currentTrack);

            this.playlist = newList;
        },
        stopAudio(reset = false) {
            console.log("pausing player");
            this.audioPlayer.pause();
            if (reset === true) {
                this.playlistPosition = 0;
            }
            this.loading = false;
            this.body.classList.remove("media-playing");
            this.currentTrackTime = "";
            this.currentTrackTitle = "";
            document
                .querySelectorAll(".audio-player__playlist__item")
                .forEach((item) => item.classList.remove("playing"));
        },
        startDrag(evt) {
            evt.dataTransfer.dropEffect = "move";
            evt.dataTransfer.effectAllowed = "move";
            evt.dataTransfer.setData("itemID", evt.target.id);
        },
        onDrop(evt) {
            const itemID = evt.dataTransfer.getData("itemID");
            this.playlist.push(itemID);
        },
        drop(event) {
            event.preventDefault();
        },
        removeItem(event) {
            this.playlist.splice(event.target.parentElement.dataset.index, 1);
        },
        startStopTimer() {
            if (this.mediaPlaying) {
                return this.enableCountdown();
            } else {
                return this.enableCountdown(false);
            }
        },
    },
    computed: {
        audioPlaylist() {
            const playlistPanelList = [];
            this.playlist.forEach((item) => playlistPanelList.push(item.name));
            return playlistPanelList;
        },
        updateTimeRemaining() {
            return this.convertSecondsToTime(this.currentTimeRemaining);
        },
        filteredAudioFiles() {
            const audioFiles = this.media.filter(
                (file) =>
                    this.supportedAudioFormats.indexOf(
                        file.media_details.dataformat
                    ) !== -1
            );
            return audioFiles.filter((file) => file.categories[0] === 2);
        },
        itemsInPlaylist() {
            return this.playlist.length > 0;
        },
    },
    watch: {
        mediaPlaying() {
            this.$emit("statusupdate", this.mediaPlaying);
            this.$emit("currentaudiosrc", this.audioPlayer);
            let panelHeight = document.querySelector(
                ".audio-player__now-playing"
            ).offsetHeight;
            document.documentElement.style.setProperty('--controls-margin-top', this.mediaPlaying ? panelHeight + 'px' : '0px')
        },
    },
};
</script>

<style lang="scss" >
@use "../scss/buttons";
@use "../scss/variables";
@use "../scss/breakpoints";
@use "../scss/core-mixins";


.audio-player {
    grid-area: main;
    display: grid;
    padding: 20px;
    padding-top: 0;
    gap: 10px;
    align-items: flex-start;
    z-index: 99;
    grid-template:
        "title" min-content
        "controls"
        "content" / 1fr;

    @include core-mixins.device(breakpoints.$tabPortrait) {
        grid-template:
            "title" min-content
            "controls"
            "content" / 1fr;
    }

    &__title {
        text-shadow: 2px 2px red;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__controls {
        grid-area: controls;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        z-index: 99;
        grid-area: controls;
        box-sizing: border-box;
        z-index: 99;
        border-radius: 25px;
        position: sticky;
        transition: top 0.3s ease-out;
        top: var(--controls-margin-top);
        bottom: 0;
        right: 0;
        padding: 10px 0px;
        gap: 5px;
        width: 100%;
        grid-template-columns: repeat(5, auto);
        backdrop-filter: blur(10px);

        @include core-mixins.device(breakpoints.$tabPortrait) {
            grid-template-columns: repeat(5, auto);
            position: static;
            width: auto;
            bottom: unset;
            right: 0;
            transform: unset;
            top: 0;
            right: 0;
        }

        &__button {
            margin: auto;
            text-transform: inherit;
        }
    }

    &__content {
        grid-area: content;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        gap: 15px;
        align-items: flex-start;
    }

    display: grid;
    justify-content: center;

    &__track {
        margin-bottom: 40px;
    }

    &__message-box {
        position: fixed;
        right: 50%;
        transform: translateX(50%);
        top: 30%;
        box-shadow: var(--light-shadow);
        padding: 20px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: black;
        opacity: 0;
        pointer-events: none;
        transition: 0.5s;
        z-index: 999;
    }

    &__spinner {
        opacity: 0;
        transition: opacity 0.3s;
        margin: auto;
        height: auto;
        max-height: 0px;
        overflow: hidden;
        transition: 0.5s;

        &.loading {
            opacity: 1;
            max-height: 50px;
        }
    }

    &__stop-button {
        @include buttons.stopButton();

        &:hover {
            cursor: pointer;

            @include core-mixins.device(breakpoints.$tabPortrait) {
                background-color: lighten($color: red, $amount: 20);
            }
        }
    }

    &__now-playing {
        position: fixed;
        right: 50%;
        top: 0%;
        transform: translate(50%, -120%);
        padding: 10px 20px;
        display: grid;
        color: white;
        gap: 10px;
        background: black;
        width: auto;
        backdrop-filter: blur(4px);
        box-shadow: var(--red-shadow);
        z-index: 999;
        transition: transform 0.5s ease-out;

        @include core-mixins.device(breakpoints.$tabPortrait) {
            right: 50%;
        }

        &.show {
            transform: translate(50%, 0%);
        }
    }

    &__playlist {
        &__button-container {
            display: grid;
            grid-template:
                "title title title"
                "playall clear close" / auto auto auto;
            grid-gap: 10px;
        }

        &__close {
            grid-area: close;
            justify-self: flex-end;
        }

        &__button {
            width: auto;
            padding: 10px;
            display: flex;

            &--show-hide {
                margin-right: auto;
                width: 100px;

                @include core-mixins.device(breakpoints.$tabPortrait) {
                    min-width: 140px;
                }

                .hidden & {
                    position: absolute;
                    top: 105%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &--clear {
                grid-area: clear;
                justify-self: center;
            }

            &--show-hide {
                position: fixed;
                top: 0;
                right: 0;
                width: min-content;
                border-radius: 0 0 15px 15px;
                border-top: none;
                transform: translate(0, -110%);
                transition: transform 0.3s;

                @include core-mixins.device(breakpoints.$tabPortrait) {
                    transform: translate(110%, 0);
                    top: 15px;
                    border: 2px solid yellow;
                    border-radius: 15px;
                }

                &.show {
                    transform: translate(0, 0);
                    margin-right: 10px;
                }
            }
        }

        &__add-button {
            background-color: transparent;
            color: white;
            font-size: 20px;
            margin: auto;

            &:hover {
                cursor: pointer;
            }
        }

        &__play {
            padding: 10px;
            grid-area: playall;

            &:hover {
                cursor: pointer;
            }
        }

        &__item {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            padding: 5px;
            box-shadow: var(--red-shadow);
            display: grid;
            grid-template: "title play add" / 1fr auto auto;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                cursor: pointer;

                .audio-player__playlist__item__title {
                    color: gold;
                }
            }

            &__title {
                grid-area: title;
                color: white;
            }

            .play-button {
                @include buttons.playButtonSmall();
            }

            .add-button {
                @include buttons.addButton();
            }
        }
    }

    &__playlist-container {
        box-sizing: border-box;
        position: fixed;
        z-index: 999;
        right: 0;
        transform: translateX(0%);
        top: 0px;
        min-height: 50px;
        width: 400px;
        overflow: scroll;
        max-width: 100%;
        max-height: 100%;
        display: grid;
        gap: 20px;
        border: 2px solid green;
        box-shadow: var(--light-shadow);
        border-radius: 10px;
        padding: 20px;
        background-color: rgb(29, 4, 4);
        color: white;
        transition: transform 0.3s ease-out;

        &.hidden {
            transform: translateX(110%);
        }

        @include core-mixins.device(breakpoints.$tabPortrait) {
            top: 15px;
        }

        &__title {
            text-align: center;
            font-size: 18px;
            line-height: 120%;
            grid-area: title;
            text-shadow: 1px 1px red;

            .playing & {
                color: gold;
            }
        }

        &__list {
            box-sizing: border-box;
            background-color: rgb(72, 13, 13);
            gap: 10px;
            display: grid;
            padding: 10px;
            height: 100%;
            min-height: 75px;
            z-index: 999;

            &.empty {
                border: 1px dashed blue;

                &::before {
                    content: "Add tracks with +";
                    margin: auto;
                }
            }

            @include core-mixins.device(breakpoints.$tabPortrait) {
                padding-bottom: unset;
            }
        }

        &__list-item {
            display: grid;
            grid-template-columns: auto 1fr auto auto auto;
            justify-content: flex-start;
            gap: 10px;
            text-align: left;

            &:last-child {
                margin-bottom: 130px;
            }
        }

        &__shift {
            font-size: 30px;
            margin-left: 10px;
            margin-right: 10px;
            transform: translateY(-35%);
            justify-self: flex-end;

            &:hover {
                cursor: pointer;
            }

            &--down {
                &::after {
                    content: "\02EF";
                }
            }

            &--up {
                &::after {
                    content: "\02F0";
                }
            }
        }

        &__remove {
            font-size: 12px;
            margin-left: 10px;

            &:hover {
                cursor: pointer;
                color: red;
            }
        }
    }
}

body {
    background: #444;
}

.play-btn {
    width: 50px;
    height: 50px;
    background: radial-gradient(rgba(255, 0, 128, 0.8) 60%,
            rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: relative;
    display: block;
    margin: auto;
    box-shadow: 0px 0px 25px 3px rgba(255, 0, 128, 0.8);

    &:hover {
        cursor: pointer;
    }
}

/* triangle */
.play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    z-index: 0;

    // transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    .playing & {
        border-left: 25px solid rgb(0, 255, 30);
    }
}

/* pulse wave */
.play-btn:before {
    // content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    animation-delay: 0s;
    // animation: pulsate1 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0.75);
    top: -30%;
    left: -30%;
    background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
    0% {
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
            0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }

    100% {
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}

@keyframes pulsate1 {
    0% {
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
            0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }

    100% {
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}

.lds-ellipsis {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 70px;
    height: 50px;
}

.lds-ellipsis div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
</style>