export function fadeIn(element) {
  return element.animate(
    [
      { opacity: 0.2 },
      { transform: "scale(0)", offset: 0 },
      { transform: "scale(1.2)", offset: 0.8 },
      { transform: "scale(1)", offset: 1 },
      { opacity: 1, offset: 1 },
    ],
    { duration: 1000, fill: "forwards", easing: "ease-out" }
  );
}
export function fadeOut(element) {
  return element.animate(
    [
      { opacity: 1 },
      { transform: "scale(1)", offset: 0 },
      { transform: "scale(1.2)", offset: 0.8 },
      { transform: "scale(0)", offset: 1 },
      { opacity: 0, offset: 1 },
    ],
    { duration: 1000, fill: "forwards", easing: "ease-out" }
  );
}

export async function waitForLoad(url) {
  const loaded = new Promise((resolve) => {
    const testImage = new Image();
    testImage.addEventListener(
      "load",
      () => {
        console.log("loaded");
        return resolve(true);
      },
      { once: true }
    );
    testImage.src = url;
  }).catch(console.log("rejected"));
  return loaded;
}

const api = {
  fadeIn: fadeIn,
  waitForLoad: waitForLoad,
};

export default api;
